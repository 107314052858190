import axios from 'axios';
import { getSingleData } from '../config/localStorage';

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  'token'
)}`;

axios.interceptors.request.use(
  request => {
    console.log(request.headers.Authorization);
    if (request.headers.Authorization === 'Bearer null') {
      request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      return request;
    }
    return null;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * get method
 * @param {*} url
 * @param {*} config
 */
export const get = (url, config) => axios.get(url, config);

/**
 * post method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const post = (url, body, config) => axios.post(url, body, config);

/**
 * put method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const put = (url, body, config) => axios.put(url, body, config);

/**
 * patch method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const patch = (url, body, config) => axios.patch(url, body, config);

/**
 * delete method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const del = (url, body, config) =>
  axios.delete(url, { config, data: body });

/**
 * put method without Autherization header
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
const instance = axios.create();
export const putWithoutAuth = (url, body, config) =>
  instance.put(url, body, config);

delete instance.defaults.headers.common.Authorization;
